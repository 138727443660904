import axios from 'axios'
import createStore from './createStore'

export type LocalVersion = {
  version: string
}

export const useCurrentVersionStore = createStore<string | null>((set) => ({
  loading: false,
  loadingComplete: false,
  data: null,
  error: null,
  fetch: async () => {
    try {
      const versionresponse = await axios.get<LocalVersion>('/api/version')
      const versiondata = await versionresponse.data
      set({ data: versiondata.version, loadingComplete: true, loading: false })
    } catch (err) {
      set({ loadingComplete: true, loading: false })
    }
  },
}))
