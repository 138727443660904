import axios from 'axios'
import createStore from './createStore'
import { getSession } from 'next-auth/react'
import { getEmailDomain } from '../utils/stringutils'
import { Organization } from '../src/types/organization'

export const useUserOrganizationStore = createStore<Organization | null>((set) => ({
  loading: false,
  loadingComplete: false,
  data: null,
  error: null,
  fetch: async () => {
    try {
      const session = await getSession()
      const authDomain = getEmailDomain(session.user?.email)
      if (!authDomain) {
        set({ loadingComplete: true, loading: false, error: 'Invalid email/auth domain.' })
        return
      }
      const orgResponse = await axios.get<Organization[]>(`/api/organizations?auth_domain=${authDomain}`)
      const userOrg = orgResponse.data?.[0] || null
      if (!userOrg) {
        set({ loadingComplete: true, loading: false, error: 'No organization found.' })
        return
      }
      set({ data: userOrg, loadingComplete: true, loading: false })
    } catch (err) {
      set({ loadingComplete: true, loading: false, error: err })
    }
  },
}))
