import {
  Home as HomeIcon,
  CompareArrows as CompareArrowsIcon,
  Description as DescriptionIcon,
  Memory as MemoryIcon,
  DatasetLinked as DatasetLinkedIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material'
import { List, ListItemButton, ListItemText, SvgIcon } from '@mui/material'
import router from 'next/router'
import React from 'react'

interface SubMenuProps {
  activeTab: string
  projcetId: number
  selected: () => void
}

const SubMenuMobile = (props: SubMenuProps) => {
  const navigateTo = (e, url) => {
    props.selected()
    if (e.ctrlKey) window.open(url, '_blank')
    else router.push(url)
  }

  return (
    <List>
      <ListItemButton
        className={`${props.activeTab === '/' ? 'active' : ''}`}
        onClick={(e) => {
          navigateTo(e, `/`)
        }}
      >
        <HomeIcon />
        <ListItemText primary="Home" />
      </ListItemButton>

      {props.projcetId && (
        <>
          <ListItemButton
            className={`${props.activeTab === '/project/[id]' ? 'active' : ''}`}
            onClick={(e) => {
              navigateTo(e, `/project/${props.projcetId}`)
            }}
          >
            <MemoryIcon />
            <ListItemText primary="Loggers" />
          </ListItemButton>

          <ListItemButton
            className={`${props.activeTab === '/project/[id]/sensors' ? 'active' : ''}`}
            onClick={(e) => {
              navigateTo(e, `/project/${props.projcetId}/sensors`)
            }}
          >
            <SvgIcon>
              <path
                fill="currentColor"
                d="M7.76,16.24C6.67,15.16,6,13.66,6,12s0.67-3.16,1.76-4.24l1.42,1.42C8.45,9.9,8,10.9,8,12c0,1.1,0.45,2.1,1.17,2.83 L7.76,16.24z M16.24,16.24C17.33,15.16,18,13.66,18,12s-0.67-3.16-1.76-4.24l-1.42,1.42C15.55,9.9,16,10.9,16,12 c0,1.1-0.45,2.1-1.17,2.83L16.24,16.24z M12,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,10,12,10z M20,12 c0,2.21-0.9,4.21-2.35,5.65l1.42,1.42C20.88,17.26,22,14.76,22,12s-1.12-5.26-2.93-7.07l-1.42,1.42C19.1,7.79,20,9.79,20,12z M6.35,6.35L4.93,4.93C3.12,6.74,2,9.24,2,12s1.12,5.26,2.93,7.07l1.42-1.42C4.9,16.21,4,14.21,4,12S4.9,7.79,6.35,6.35z"
              />
            </SvgIcon>
            <ListItemText primary="Sensors" />
          </ListItemButton>

          <ListItemButton
            className={`${props.activeTab === '/project/[id]/sensorrules' ? 'active' : ''}`}
            onClick={(e) => {
              navigateTo(e, `/project/${props.projcetId}/sensorrules`)
            }}
          >
            <CompareArrowsIcon />
            <ListItemText primary="SensorRules" />
          </ListItemButton>

          <ListItemButton
            className={`${props.activeTab === '/project/[id]/documents' ? 'active' : ''}`}
            onClick={(e) => {
              navigateTo(e, `/project/${props.projcetId}/documents`)
            }}
          >
            <DescriptionIcon />
            <ListItemText primary="Documents" />
          </ListItemButton>
          <ListItemButton
            className={`${props.activeTab === '/project/[id]/logger-setup' ? 'active' : ''}`}
            onClick={(e) => {
              navigateTo(e, `/project/${props.projcetId}/logger-setup`)
            }}
          >
            <DatasetLinkedIcon />
            <ListItemText primary="Logger Setup" />
          </ListItemButton>
          <ListItemButton
            className={`${props.activeTab === '/project/[id]/settings' ? 'active' : ''}`}
            onClick={(e) => {
              navigateTo(e, `/project/${props.projcetId}/settings`)
            }}
          >
            <SettingsIcon />
            <ListItemText primary="Settings" />
          </ListItemButton>
        </>
      )}
    </List>
  )
}

export default SubMenuMobile
