import { createTheme, PaletteOptions, SimplePaletteColorOptions, ThemeOptions } from "@mui/material/styles";


export interface DefaultPaletteOptions extends PaletteOptions {
  primary?: SimplePaletteColorOptions;
  secondary?: SimplePaletteColorOptions;
  error?: SimplePaletteColorOptions;
}


export const defaultPalette: DefaultPaletteOptions = {
  primary: {
    light: "#5DA1FF",
    main: "#0073D1",
    dark: "#00499F",
    contrastText: "#fff",
  },
  secondary: {
    light: "#cecece",
    main: "#5D5E5E",
    dark: "#343434",
    contrastText: "#000",
  },
  error: {
    main: "#b00020",
  },
  background: {
    default: "#fafafa",
    paper: "#fff",
  },
  text: {
    primary: "#0D0D0D",
    secondary: "#626262",
    disabled: "#8B8B8B",
  },
};

export const themeOptions: ThemeOptions = {
  palette: defaultPalette,
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h1: {
      fontSize: "56px",
      fontWeight: "bold",
      fontFamily: ["Roboto Slab", "sans-serif"].join(","),
    },
    h2: {
      fontSize: "42px",
      fontWeight: "bold",
      fontFamily: ["Roboto Slab", "sans-serif"].join(","),
    },
    h3: {
      fontSize: "32px",
      fontWeight: "bold",
      fontFamily: ["Roboto Slab", "sans-serif"].join(","),
    },
    h4: {
      fontSize: "28px",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "22px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontFamily: ["Roboto Slab", "sans-serif"].join(","),
    },
    h6: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "14px",
      fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
    body2: {
      fontSize: "12px",
    },
    button: {
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: ["Open Sans", "sans-serif"].join(","),
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#626262",
      fontWeight: "normal",
      fontStyle: "normal",
      fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 600,
      fontStyle: "normal",
      fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
  },
  breakpoints: {
    unit: "px",
    values: {
      xs: 0,
      sm: 600,
      md: 1025,
      lg: 1280,
      xl: 1920,
    },
  },
};

const theme = createTheme(themeOptions)

export default theme;
