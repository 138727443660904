import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Box,
  Button, CircularProgress,
  Drawer,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import { TelemetryProject } from '../src/types/telemetry-project'
import { getFabColor, initials, upperCaseInitials } from '../utils/stringutils'
import SubMenu from './SubMenu'
import SubMenuMobile from './SubMenuMobile'
import { signOut } from 'next-auth/react'
import { useUserOrganizationStore } from '../stores/user-organization-store'

interface Props {
  userName: string
  userEmail: string
}

const Header = (props: Props) => {
  const { userName, userEmail } = props
  const router = useRouter()
  const orgLoading = useUserOrganizationStore((state) => state.loading)
  const orgLoadingComplete = useUserOrganizationStore((state) => state.loadingComplete)
  const organization = useUserOrganizationStore((state) => state.data)
  const { id: projectId } = router.query
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)
  const { data: projects } = useSWR<TelemetryProject[]>('/api/projects', { suspense: false })
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const { publicRuntimeConfig } = getConfig()
  const env = publicRuntimeConfig.ENV

  const [currentProject, setCurrentProject] = useState<TelemetryProject | undefined>(undefined)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (projectId && projects) {
      const project = projects.find((p) => p.projectid === +projectId)
      setCurrentProject(project)
    }
  }, [projectId, projects])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [projectAnchorEl, setProjectAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleProjectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProjectAnchorEl(event.currentTarget)
  }

  const handleProjectClose = () => {
    setProjectAnchorEl(null)
  }

  const navigateToProject = (project?: number) => {
    router.push(project ? `/project/${project}` : `/project`)
    handleProjectClose()
  }

  const activeTab = useMemo(() => {
    switch (router.pathname.split('/')[1]) {
      case '':
        return 0
      case 'project':
        return 4
      case 'sources':
        return 2
        case 'catalogs':
          return 3
      case 'datapoints':
        return 1
      default:
        return false
    }
  }, [router.pathname])
  const activeSubPath = useMemo(() => {
    return router.pathname
  }, [router.pathname])
  const drawerClassName = useMemo(
    () => `${activeTab === 4 && router.query.id ? 'show' : ''} drawer`,
    [activeTab, router.query.id]
  )
  return (
    <div className="header">
      <AppBar color="secondary" position="fixed">
        <Toolbar className="flex toolbar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: 'block', md: 'none' } }}
            size="large"
          >
            <MenuIcon htmlColor="white" />
          </IconButton>
          <Box component="span" sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img src="/logo/logo-ngi-white2.svg" height="24px" />
          </Box>
          <span className="bar" />
          <Typography variant="h5" noWrap style={{ fontSize: '1rem' }}>
            LIVE ADMIN
          </Typography>
          <Box component="span" sx={{ display: { xs: 'block', sm: 'none' } }}>
            {['TEST', 'DEV'].includes(env) && <div className={`env ${env}`}>{env}</div>}
          </Box>
          <Box component="span" sx={{ display: { xs: 'none', sm: 'block' } }}>
            {['TEST', 'DEV'].includes(env) && <div className={`env ${env}`}>{env} ENVIRONMENT</div>}
          </Box>

          <Tabs
            sx={{ display: { xs: 'none', md: 'block' } }}
            className="tabs"
            value={activeTab}
            indicatorColor="primary"
            textColor="inherit"
          >
            <Tab label="home" onClick={() => router.push('/')} />
            <Tab label="Raw datapoints" onClick={() => router.push('/datapoints')} />
            <Tab label="Sources" onClick={() => router.push('/sources')} />
            <Tab label="Catalogs" onClick={() => router.push('/catalogs')} />
            <Tab
              label="Project"
              onClick={() => currentProject && router.push(`/project/${currentProject.projectid}`)}
            />
          </Tabs>

          <Tooltip enterDelay={500} placement="bottom-end" title={currentProject?.name || 'Select project'}>
            <Button
              className="shortbutton"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleProjectClick}
            >
              <Typography color="textSecondary" variant="subtitle2" noWrap>
                {currentProject ? `${currentProject.projectid} - ${currentProject.name} ` : 'Select project'}
              </Typography>
              <KeyboardArrowDownIcon />
            </Button>
          </Tooltip>

          <Menu
            style={{ zIndex: 9999 }}
            id="simple-menu"
            anchorEl={projectAnchorEl}
            keepMounted
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(projectAnchorEl)}
            onClose={handleProjectClose}
          >
            <MenuItem key={0} onClick={() => navigateToProject()}>
              Add project
            </MenuItem>
            {projects &&
              projects
                .sort((a, b) => a.projectid - b.projectid)
                .map((p) => (
                  <MenuItem key={p.projectid} onClick={() => navigateToProject(p.projectid)}>
                    {p.projectid} - {p.name}
                  </MenuItem>
                ))}
          </Menu>

          {
            orgLoading || !orgLoadingComplete ? <CircularProgress /> : organization !== null ? (<Box component="span" sx={{ display: { xs: 'block', sm: 'block' } }}>
              <Fab
                size="small"
                style={{
                  height: '32px',
                  width: '32px',
                  minHeight: '32px',
                  color: 'white',
                  backgroundColor: getFabColor(organization.id),
                }}
                color="inherit"
                aria-label="add"
              >
                {upperCaseInitials(organization.name)}
              </Fab>
            </Box>) : null
          }

          <Box component="span" sx={{ display: { xs: 'block', sm: 'block' } }}>
            <span style={{ cursor: 'pointer' }} onClick={handleClick}>
              <Fab
                size="small"
                style={{
                  height: '32px',
                  width: '32px',
                  minHeight: '32px',
                  color: 'white',
                  backgroundColor: getFabColor(userEmail),
                }}
                color="inherit"
                aria-label="add"
              >
                {upperCaseInitials(userName)}
              </Fab>
            </span>
            <Menu
              style={{ zIndex: 9999 }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => signOut()}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <nav className={''} aria-label="mailbox folders">
        <Drawer
          open={mobileOpen}
          sx={{ display: { xs: 'block', md: 'none' } }}
          classes={{
            paper: 'paper',
          }}
          className="mobileMenue"
        >
          <SubMenuMobile
            projcetId={currentProject?.projectid}
            activeTab={activeSubPath}
            selected={() => {
              setMobileOpen(false)
            }}
          />
        </Drawer>

        <Drawer
          classes={{
            paper: drawerClassName,
          }}
          sx={{ display: { xs: 'none', md: 'block' } }}
          variant="permanent"
          open
        >
          <SubMenu projcetId={router.query.id as string} activeTab={activeSubPath} />
        </Drawer>
      </nav>
    </div>
  )
}

export default Header
