import {create} from 'zustand'
import { StateCreator } from 'zustand/vanilla'

export type StoreState<T> = {
  loading: boolean
  loadingComplete: boolean
  error: string | null
  data: T
  fetch: (...args: unknown[]) => Promise<void>
}

export default function createStore<T>(createState: StateCreator<StoreState<T>>) {
  return create<StoreState<T>>(createState)
}
