// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { RewriteFrames } from '@sentry/integrations';

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig()

const SENTRY_DSN = publicRuntimeConfig.SENTRY_DSN
const SENTRY_ENV = publicRuntimeConfig.SENTRY_ENV

if (SENTRY_DSN && SENTRY_ENV) {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://a29b593d5c35431e8b9d69583517929f@o469029.ingest.sentry.io/5790312',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
    environment: SENTRY_ENV,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations: [new RewriteFrames({
      iteratee: frame => {
        // decode square bracket notation for dynamic routes
        frame.filename = decodeURI(frame.filename);
        return frame;
      },
    })]
  });
}