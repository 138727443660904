import {
  CompareArrows as CompareArrowsIcon,
  Description as DescriptionIcon,
  Memory as MemoryIcon,
  DatasetLinked as DatasetLinkedIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material'
import { Divider, List, ListItemButton, Tooltip } from '@mui/material'
import router from 'next/router'
import React from 'react'

interface SubMenuProps {
  activeTab: string
  projcetId: string
}

const SubMenu = (props: SubMenuProps) => {
  const navigateTo = (e, url) => {
    if (e.ctrlKey) window.open(url, '_blank')
    else router.push(url)
  }

  return (
    <>
      <List>
        <ListItemButton
          className={`${props.activeTab === '/project/[id]' ? 'active' : ''}`}
          onClick={(e) => {
            navigateTo(e, `/project/${props.projcetId}`)
          }}
        >
          <Tooltip title="Project and loggers" placement="right">
            <MemoryIcon />
          </Tooltip>
        </ListItemButton>
        <Divider
          className={`${
            props.activeTab === '/project/[id]' || props.activeTab === '/project/[id]/sensors' ? 'hidden' : ''
          }`}
        />

        <ListItemButton
          className={`${props.activeTab === '/project/[id]/sensors' ? 'active' : ''}`}
          onClick={(e) => {
            navigateTo(e, `/project/${props.projcetId}/sensors`)
          }}
        >
          <Tooltip title="Sensors" placement="right">
            <img style={{ margin: 'auto' }} src="/icons/sensors-white-24dp.svg" />
          </Tooltip>
        </ListItemButton>

        <Divider
          className={`${
            props.activeTab === '/project/[id]/sensors' || props.activeTab === '/project/[id]/documents' ? 'hidden' : ''
          }`}
        />

        <ListItemButton
          className={`${props.activeTab === '/project/[id]/sensorrules' ? 'active' : ''}`}
          onClick={(e) => {
            navigateTo(e, `/project/${props.projcetId}/sensorrules`)
          }}
        >
          <Tooltip title="Rules" placement="right">
            <CompareArrowsIcon />
          </Tooltip>
        </ListItemButton>
        <Divider
          className={`${
            props.activeTab === '/project/[id]/sensorrules' || props.activeTab === '/project/[id]/sensorrules'
              ? 'hidden'
              : ''
          }`}
        />

        <ListItemButton
          className={`${props.activeTab === '/project/[id]/documents' ? 'active' : ''}`}
          onClick={(e) => {
            navigateTo(e, `/project/${props.projcetId}/documents`)
          }}
        >
          <Tooltip title="Documents" placement="right">
            <DescriptionIcon />
          </Tooltip>
        </ListItemButton>
        <Divider
          className={`${
            props.activeTab === '/project/[id]/documents' || props.activeTab === '/project/[id]/settings'
              ? 'hidden'
              : ''
          }`}
        />
        <ListItemButton
          className={`${props.activeTab === '/project/[id]/settings' ? 'active' : ''}`}
          onClick={(e) => {
            navigateTo(e, `/project/${props.projcetId}/settings`)
          }}
        >
          <Tooltip title="Settings" placement="right">
            <SettingsIcon />
          </Tooltip>
        </ListItemButton>
        <Divider className={`${props.activeTab === '/project/[id]/settings' ? 'hidden' : ''}`} />
        <ListItemButton
          className={`${props.activeTab === '/project/[id]/logger-setup' ? 'active' : ''}`}
          onClick={(e) => {
            navigateTo(e, `/project/${props.projcetId}/logger-setup`)
          }}
        >
          <Tooltip title="Logger Setup" placement="right">
            <DatasetLinkedIcon />
          </Tooltip>
        </ListItemButton>
      </List>
    </>
  )
}

export default SubMenu
