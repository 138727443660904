export function trunc(foo: string, n): string {
  if (!foo) return ''
  return foo.substr(0, n - 1) + (foo.length > n ? '...' : '')
}

export const validateNumber = /^-?[0-9]*((,|\.)[0-9]*)?$/
export const validateScientificNumber = /^-?(\d\.?\d*[Ee][+\-]?\d+|(\d+\.\d*|\d*\.\d+)|\d+)$/

export function hashCode(s): number {
  if (!s) return 0

  let h: number
  for (let i = 0; i < s.length; i++)
    h = Math.imul(31, h) + s.charCodeAt(i) | 0;

  return h;
}

export function initials(name: string): string {
  if (!name)
    return '//'

  const names = name.split(' ')
  return (names.length > 1 ? names[0][0] + names[names.length - 1][0] : names[0][0]).toUpperCase()
}

const fabColorTable = ['#00883b', '#a36800', '#1940D7', '#1e7eaf', '#e56db1', '#008a0c', '#7e7474']

export function getFabColor(source: string): string {
  const index = Math.abs(hashCode(source)) % fabColorTable.length

  return fabColorTable[index]

}

export function upperCaseInitials(str: string) {
  return str.split(' ').length > 1
    ? str
      .split(' ')
      .map((n) => n[0].toUpperCase())
      .join('')
    : str
}

export function getEmailDomain(email: string) {
  return email?.split('@')[1]
}

export const isvalidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const isValidAuthDomain = (authDomain: string) => {
  return /\S+\.\S+/.test(authDomain)
}
